












import {
  Component,
  Vue,
  Prop,
  Watch,
  Inject,
  Provide,
  Emit,
} from "vue-property-decorator";
import { checkIsMicroApp } from "@/scripts/load-micro-app";
import ContainerHeaderTitle from "../components/container-header-title/index.vue";
import ContainerDialog from "../components/container-dialog/index.vue";
// import {} :infoItemDetailData="infoItemDetailData" :detailModal="detailModal"

interface Vdata {
  name: string;
}

@Component({
  components: {
    ContainerHeaderTitle,
    ContainerDialog,
  },
})
export default class MyCollect extends Vue {
  // data
  private data: Vdata = {
    name: "我的收藏",
  };

  private infoItemDetailData: any = {
    publishTime: "0000-00-00 00:00:00",
    typeId: ["VIDEO"],
    frameImageIds: [],
    playVideo: false, // 默认不展示视频播放器
    title: "",
    likesCount: 0,
    commentsCount: 0,
    extractedContent: "",
  };

  private detailModal: boolean = false;

  // methods
  private mounted(): void {
    this.$nextTick(() => {
      checkIsMicroApp.bind(this)(); // 加载微应用
    });
    //
    this.actions.onGlobalStateChange((state: any, prev: any) => {
      // state: 变更后的状态; prev 变更前的状态
      console.log(state, prev);
      if (state.type === "collect-detail") {
        // state
        // console.log(state);
        let payload:any = state.payload;
        let index:string = payload.infoType === 1 ? "cloud-view-video-0" : "cloud-view-image-0";
        // let url:string = `${this.$store.state.common.uviewServer}data/details/${payload.docId}/cloud-view-image-0`;
        let url:string = `${this.$store.state.common.uviewServer}collection/info/${payload.id}`;
        this.axios.get(url).then((res: any) => {
          // debugger;
          console.log(res)
          if (res.data.code.substr(0, 1) === "R") {

            if(!res.data.data){
              this.$message({message:'暂无数据',type:'error'}); return;
            }

            this.infoItemDetailData.collection = res.data.data.collection;
            this.infoItemDetailData.info = res.data.data.info;
            this.infoItemDetailData = {
              collection: res.data.data.collection,
              ...res.data.data.info,
              playVideo: false,
            };
            this.detailModal = true;
          }
          console.log(this.infoItemDetailData,this.detailModal);
        });
      }
    });
  }
}
