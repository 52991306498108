









































































































































































import {
  Component,
  Vue,
  Prop,
  Watch,
  Inject,
  Provide,
  Emit,
} from "vue-property-decorator";

@Component({
  components: {},
})
export default class ContainerDialog extends Vue {
  /*

    publishTime: "0000-00-00 00:00:00",
    typeId: ["VIDEO"],
    frameImageIds: [],
    playVideo: false, // 默认不展示视频播放器
  */

  // props
  @Prop({
    default: () => ({
      publishTime: "0000-00-00 00:00:00",
      typeId: ["VIDEO"],
      frameImageIds: [],
      playVideo: false, // 默认不展示视频播放器
    }),
  })
  readonly infoItemDetailData: any | undefined; // 事件推送 视图搜索

  @Prop({ default: true, type: Boolean }) detailModal: boolean | undefined; // 图片 image 、视频 video

  @Watch("infoItemDetailData", { immediate: true, deep: true })
  onChange(newVal: any, oldVal: any) {
    console.log(newVal);
  }

  // emit
  @Emit("update:detailModal")
  onOffdetailModal(status: boolean) {
    return status;
  }

  // private infoItemDetailData:any = {
  //   publishTime: "0000-00-00 00:00:00",
  //   typeId: ["VIDEO"],
  //   frameImageIds: [],
  //   playVideo: false, // 默认不展示视频播放器
  // }

  // methods 点击播放视频
  private playVideo(): void {
    this.infoItemDetailData.playVideo = true;
  }

  private mounted(): void {
    console.log(this.infoItemDetailData);
  }

  // methods 去到源视频地址 图片地址
  private goToSourceLinkPage(): void {
    window.open(this.infoItemDetailData.url, "_blank");
  }

  // 关闭窗口
  private closeDetailModal(): void {
    this.onOffdetailModal(false); // 关闭窗口
  }
}
